<template>
  <div>
    <PageHeader />
    <b-overlay :show="bot.isFetchingBotSettings">
      <b-card class="mb-4">
        <b-row v-if="botSettings.length > 0">
          <b-col
            v-for="setting in botSettings"
            :key="setting.id"
            cols="12"
          >
            <BotSettingForm :input="setting" />
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    BotSettingForm: () => import('../../../components/forms/bot-setting-form'),
  },
  page: {
    title: 'Bot Settings',
  },
  computed: {
    ...mapState(['bot']),
    ...mapGetters(['botSettings']),
  },
  created() {
    this.fetchBotSettings()
  },
  methods: {
    ...mapActions(['fetchBotSettings', 'flushQueue']),
    onClick() {
      this.flushQueue()
    },
  },
}
</script>
